<template>
  <div/>
</template>

<script>
import { StringUtils } from '@/common/string.utils';

export default {
  name: 'NFCScanner',
  props: {
  },
  data() {
    return {
      rfid: '',
      abortController: null,
    };
  },
  created() {
    // Check to see if using the Fully Kiosk Browser.
    // If so, we can use the Fully Browser's NFC reader.
    if (this.fully) {
      this.startFullyKioskScanning();
      addEventListener('onNfcTagDiscovered', this.onNfcTagDiscovered);
    }
    // Check to see if the NDEFReader is available on this browser.
    // Check:
    // - Using Chrome ver 89+
    // - Installed a local SSL cert for https (must have)
    else if ('NDEFReader' in window) {
      this.startScanning();
    }
    else {
      console.log('No NFC reader found');
    }
  },
  beforeDestroy() {
    if (this.abortController) {
      this.abortController.abort();
    }
    if (this.fully) {
      removeEventListener('onNfcTagDiscovered', this.onNfcTagDiscovered);
    }
  },
  mounted() {
    // If using the Fully Kiosk Browser, then inject the event handler for
    // the fully.onNfcTagDiscovered event into the DOM.
    let script = document.createElement('script')
    let content = document.createTextNode('function onNfcTagDiscovered(serial, type, message) {dispatchEvent(new CustomEvent("onNfcTagDiscovered", {detail:serial}))}');
    script.appendChild(content);
    document.head.appendChild(script);
  },
  methods: {
    async startScanning() {
      console.log('> Trying NFC scan');
      try {
        this.abortController = new AbortController();
        this.abortController.signal.onabort = (() => {
          console.log('> NFC scan has been aborted');
        });

        /*global NDEFReader*/
        const ndefReader = new NDEFReader();
        await ndefReader.scan({ signal: this.abortController.signal });
        console.log('> NFC scan started');

        ndefReader.addEventListener('readingerror', () => {
          console.log('Argh! Cannot read the NFC tag. Try again?');
        });

        ndefReader.addEventListener('reading', ({ message, serialNumber }) => {
          console.log(`> UID: ${serialNumber}`);
          console.log(`> Records: (${message.records.length})`);
          this.onScanPlayer(serialNumber);
        });
      } catch (error) {
        this.fully.showToast('NFC scan started');
      }
    },
    async startFullyKioskScanning() {
      console.log('> Trying Fully Kiosk NFC scan');
      try {
        /*Fully Kiosk Browser NDEFReader*/
        this.fully.nfcScanStart();
        this.fully.showToast('NFC scan started');
        console.log('> NFC scan started');
      } catch (error) {
        console.log('NFC scan error " + error');
      }
    },
    onNfcTagDiscovered(event) {
      //this.fully.showToast('NFC Tag Discovered: ' + event.detail);
      this.onScanPlayer(event.detail);
    },
    onScanPlayer(value) {
      // clean up the value
      value = StringUtils.scrubRfid(value);

      // reverse the UID if necessary
      if(this.$appConfig.isRFIDReversed){
        value = StringUtils.reverseRfidBytes(value);
      }
      this.rfid = value;
      if (StringUtils.isValidRfid(this.rfid)) {
        this.$emit('scanned', this.rfid);
      }
    },
  },
  computed: {
  },
};
</script>