<template>
  <div class="checkin-view">
    <div class="main-panel">
      <b-toast :show="showErrorAlert" dismissible variant="danger" class="mx-3"
        >{{errorMessage}}</b-toast>

      <PageHeaderKiosk/>
      <div class="text-center">
        <h1>Player Sign In</h1>
      </div>
      <div class="container-fluid" style="max-width: 400px">
        <div class="my-2 py-2">
          <div v-if="this.isNfcEnabled" class="text-muted my-2">Scan or enter the RFID on your wristband:</div>
          <div v-if="!this.isNfcEnabled" class="text-muted my-2">Enter the RFID on your wristband:</div>
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text material-icons nfc-icon text-secondary my-2"
              id="rfid-label" style="font-size: 1em">nfc</span>
            <b-form-input
              v-model="playerRfid"
              placeholder="RFID..."
              ref="inputRfid"
              :state="isValid"
              :formatter="formatter"
              maxlength="8"
              autocomplete="off"
              aria-describedby="rfid-label"
              class="mb-2 mt-2"
              size="lg"
              @keyup.enter.native="onKeyUp"
            ></b-form-input>
          </div>

          <div class="container">
            <div class="row">
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('1')"><h3>1</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('2')"><h3>2</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('3')"><h3>3</h3></button>
              <!-- <div class="col w-100 m-1"/> -->
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('A')"><h3>A</h3></button>
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('B')"><h3>B</h3></button>
            </div>
            <div class="row">
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('4')"><h3>4</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('5')"><h3>5</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('6')"><h3>6</h3></button>
              <!-- <div class="col w-100 m-1"/> -->
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('C')"><h3>C</h3></button>
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('D')"><h3>D</h3></button>
            </div>
            <div class="row">
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('7')"><h3>7</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('8')"><h3>8</h3></button>
              <button type="button" class="btn btn-outline-primary col w-100 m-1"
                @click="onButtonClick('9')"><h3>9</h3></button>
              <!-- <div class="col w-100 m-1"/> -->
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('E')"><h3>E</h3></button>
              <button type="button" class="btn btn-outline-info col w-100 m-1"
                @click="onButtonClick('F')"><h3>F</h3></button>
            </div>
            <div class="row">
              <button type="button" class="btn btn-outline-primary col-6 m-1"
                @click="onButtonClick('0')"><h3>0</h3></button>
              <!-- <div class="col w-100 m-1"/> -->
              <button type="button" class="btn btn-outline-secondary col w-100 m-1"
                @click="onButtonClick('back')">
                <span class="material-icons icon" style="font-size:xx-large;"><h3>backspace</h3></span>
              </button>
              <button type="button" :class="`btn col w-100 m-1 ${this.getOKbuttonClass()}`"
                @click="onButtonClick('ok')"><h3>OK</h3></button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <NFCScanner @scanned="onScanPlayer"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeaderKiosk from '@/components/PageHeaderKiosk.vue'
import NFCScanner from '@/components/NFCScanner.vue'
// import ScanButton from '@/components/ScanButton.vue'
// import SearchNameButton from '@/components/SearchNameButton.vue'
import { PLAYERS_LOGIN_PLAYER, PLAYERS_NEW_PLAYER, PLAYERS_SUCCESS }
  from '@/store/modules/players.store'
import { StringUtils } from "@/common/string.utils";
import Keycode from '@/common/keycode';
import Vue from 'vue';

export default {
  name: 'PlayerScanView',
  components: {
    PageHeaderKiosk,
    NFCScanner,
  },
  data() {
    return {
      playerRfid: '',
      id: null,
      displayName: null,
      fullName: null,
      showSpinner: false,
    }
  },
  created() {
    // reset any lingering errors
    this.$store.commit(PLAYERS_SUCCESS);

    // load the current player if available.
    this.playerRfid = '';
    this.$store.commit(PLAYERS_NEW_PLAYER);
    //store the url param (rfid) value
    const urlRfid = this.$route.params.rfid;
    //if has value
    if(urlRfid){
      //scan the player
      this.onScanPlayer(urlRfid.toUpperCase());
    }
  },
  mounted() {
    this.$refs.inputRfid.$el.focus();
  },
  computed: {
    canEdit() {
      return this.id !== null;
    },
    isValid() {
      const len = this.playerRfid? this.playerRfid.trim().length : 0;
      return len != 0
        ? StringUtils.isValidRfid(this.playerRfid)
        : null;
    },
    showErrorAlert() {
      if (this.$store.state.players.error) {
        this.$bvToast.toast(this.$store.state.players.error, {
          title: 'Sign In Failed',
          toaster: 'b-toaster-top-center',
          variant: 'danger',
          solid: true,
        });
      }
      return (this.$store.state.players.error? true : false);
    },
    errorMessage() {
      return this.$store.state.players.error;
    },
    isNfcEnabled() {
      return ('NDEFReader' in window || this.fully);
    },
  },
  methods: {
    formatter(value) {
      return value.toUpperCase();
    },
    getPlayer() {
      const player = this.$store.state.players.player;
      this.setPlayer(player);
    },
    setPlayer(player) {
      this.player = JSON.parse(JSON.stringify(player));
      this.id = player._id;
      this.playerRfid = player.customId;
      this.displayName = player.displayName;
      this.fullName = player._id?
        ((player.firstName? player.firstName : '-') + ' ' +
        (player.lastName? player.lastName : '')).trim()
        : null;
    },
    onScanPlayer(value) {
      this.playerRfid = value;
      console.log(`playerRfid:${value}`);
      this.$store.dispatch(PLAYERS_LOGIN_PLAYER, value)
        .then(() => {
          this.setPlayer(this.$store.state.players.player);
          if (this.player._id) {
            Vue.prototype.playAudio("scanSuccess");
            this.$router.push(`/playerprofile/`);
          }
          else{
            Vue.prototype.playAudio("scanFailure");
          }
        })
        .catch((e) =>{
          Vue.prototype.playAudio("scanFailure");
          console.log(e);
          this.setPlayer(this.$store.state.players.player);
        });
    },
    onScanCanceled() {
      this.playerRfid = null;
      this.id = null;
      this.displayName = null;
      this.fullName = null;
    },
    getPlayerColor() {
      return this.id? 'text-success' : 'text-danger';
    },
    getOKbuttonClass() {
      return this.isValid? ' btn-success' : ' btn-outline-secondary';
    },
    onButtonClick(key) {
      this.$store.commit(PLAYERS_SUCCESS);
      if (key === 'back') {
        this.playerRfid = this.playerRfid.substring(0, this.playerRfid.length-1);
      } else if (key === 'ok') {
        if (this.isValid) {
          this.onScanPlayer(this.playerRfid);
        }
        else{
          Vue.prototype.playAudio("scanFailure");
        }
      } else {
        this.playerRfid = this.playerRfid? this.playerRfid : '';
        if (this.playerRfid.length < 8) {
          this.playerRfid = (this.playerRfid? this.playerRfid : '') + key;
        }
      }
      this.$refs.inputRfid.$el.focus();
    },
    onKeyUp(event) {
      if (event.keyCode === Keycode.ENTER_KEY) {
        event.preventDefault();
        this.onButtonClick('ok');
      }
    },
  }
}
</script>

<style scoped>
h1 {
  text-transform: uppercase;
}
.nfc-icon {
  font-size:4em;
}
</style>
